'use strict';

var isProgramDisabled = require('./isProgramDisabled');
var Colors = require('./Colors');
var Icons = require('./Icons');

var TreeItemTools = require('./TreeItemTools');
var TagsUtils = require('./TagsUtils');

const {
  isDcxContentId,
  getRelevantSharedDataReferenceNames,
} = require('../utils/clubmedPagesContents');

const { 
  getIsDescendantOfCommercialAnimation, 
  getReleventSharedCommercialComponentsReferenceNames,
} = require('../utils/commercialAnimationUtils');

var Utils = require('./utils');
var config = require('models/config');
const { PAGES_STR, getSharedDataProperty } = require('./clubmedPagesContents');

const READ_ONLY = 'readonly';

var TreeItemConstructor = function (contentEditable, isCurrentUserDev, release) {
  return BackTree.Item.extend({
    getTpl: function getTpl() {
      var schema = this.getSchema(release);
      var value = this.model.get('value');
      var checkbox = '';
      var ref = this.model.get('ref');
      var isAtRootLevel = Utils.getParentPath(ref, '.') == '';
      var isAChildOfPagesNode = Utils.getLastPathPart(Utils.getParentPath(ref, '.'), '.') === PAGES_STR;
      var isAChildOfthematicPagesNode = Utils.getParentPath(ref, '.') == 'thematicPages';

      if (!isAtRootLevel && (isAChildOfPagesNode || isAChildOfthematicPagesNode) && release.get('status') != 'live') {
        checkbox = '<input ' +
          'class="checkbox-for-grouped-action" ' +
          'type="checkbox" ' +
          'name="checkbox" ' +
          'value="' + value.pageId + '" ' +
          'data-guid="' + this.model.attributes.guid + '"' +
          'data-ref="' + ref + '"' + 
          ' onclick="(function() { ' +
          '  var checkedCheckbox = $(\'.checkbox-for-grouped-action:checked\'); ' +
          'var shouldShowGroupedActionToolbar = checkedCheckbox.length > 1 ;' +
          'if(shouldShowGroupedActionToolbar){ $(\'#grouped-action-bar\').show() } else{ $(\'#grouped-action-bar\').hide() } ' +
          '})();"/>';
      }

      var parentIsArray = this.model.get('parentIsArray');
      var hasNodeProgramDisabledDates = this.hasNodeProgramDisabledDates(this.model, value, parentIsArray);
      var nodeDisabled = (hasNodeProgramDisabledDates) ? this.getNodeProgramDisabled(this.model, value) : this.getNodeDisabled(this.model, schema, value, parentIsArray);
      var nodeDisabledClasses = (nodeDisabled) ? 'deleted-text ' : 'toggle ';
      const restrictedAccess = this.getRestrictedAccess(schema);
      const isReadOnly = READ_ONLY === restrictedAccess;
      const dndClass = (parentIsArray && contentEditable && !isReadOnly && (!restrictedAccess || isCurrentUserDev)) ? '' : ' no-dnd never-dnd';
      var hasChildrens = _.isArray(this.model.get('value')) ? 'hasChildren' : '';
      var tpl =
        '<div class="wrapper clearfix' + dndClass + '">'
        + '<div class="left-part no-dnd never-dnd">'
        + checkbox
        + '<a class="' + nodeDisabledClasses + 'cms-button" href="#">'
        + '<i class="openable fa fa-folder-o  ' + hasChildrens + '"></i>'
        + '</a>'
        + '</div>\n <div class="body-part">'
        + (this.getBodyPart())
        + '</div>'
        + '<div class="right-part no-dnd never-dnd">'
        + (this.getRightPart())
        + '</div>'
        + '</div>';

      return tpl;
    },

    _isFirstArray: function _isFirstArray(model) {
      while (model && model.parent()) {
        if (model.get('parentIsArray')) {
          return false;
        }
        model = model.parent();
      }
      return true;
    },

    getBodyPart: function getBodyPart() {
      const contentId = release?.get('content_id') ?? '';
      var schema = this.getSchema(release);
      var value = this.model.get('value');
      var tpl = '<div class="tree-node" >';
      var contentValue = this.model.get('contentValue');
      var ref = this.model.get('ref');
      var parentIsArray = this.model.get('parentIsArray');
      var hasNodeProgramDisabledDates = false;
      var nodeDisabledAttributeForDisabledCheckbox = '';
      var nodeProgramDisabledClass = '';
      var nodeDisabled = false;
      var statusClasses;
      var nodeDisabledClasses = 'toggle ';
      var nodeDisabledAttributeForButtons = '';
      var disableCheckboxTitle = '';
      var isDescendantOfSharedDataNode = Utils.getFirstPathPart(ref) === getSharedDataProperty(contentId);
      var isDescendantOfCommercialAnimation = getIsDescendantOfCommercialAnimation(ref);
      var isAChildOfPagesNode = Utils.pathContainsElement(ref, PAGES_STR);
      var title = TreeItemTools.getNodeTitle(this.model, release);
      var elementsToHide = ['b2c-pagesId', 'old_position', '_id'];
      const tagsMetadata = release?.get('tagsMetadata') ?? {};

      var complement = '';
      var parentElement = Utils.getLastPathPart(Utils.getParentPath(ref, '.'));

      if (elementsToHide.indexOf(title) === -1) {
        if (this.model && this.model.get('nodes') && this.model.get('nodes')[0] && this.model.get('nodes')[0].value && parentElement === 'lines') {
          if (this.model.get('nodes')[0].value.title) {
            complement = ' <b> (' + this.model.get('nodes')[0].value.title.toLowerCase().trim() + ') </b>';
          }
        } else if (isDescendantOfSharedDataNode) {
          if (this.model && this.model.get('value')) {
            complement = this.model.get('value').title ? ' <b> (' + this.model.get('value').title + ') </b>' : complement;
          }
        }
        if (_.isObject(value) || _.isArray(value)) {
          hasNodeProgramDisabledDates = this.hasNodeProgramDisabledDates(this.model, value, parentIsArray);
          nodeDisabled = (hasNodeProgramDisabledDates) ? this.getNodeProgramDisabled(this.model, value) : this.getNodeDisabled(this.model, schema, value, parentIsArray);
          disableCheckboxTitle = (nodeDisabled) ? '"Enable"' : '"Disable"';
          if (nodeDisabled) {
            nodeDisabledClasses = 'deleted-text ';
            nodeDisabledAttributeForButtons = ' disabled="disabled"';
          }
          if (hasNodeProgramDisabledDates) {
            nodeDisabledAttributeForDisabledCheckbox = ' disabled="disabled"';
            nodeProgramDisabledClass = (nodeDisabled) ? ' red' : ' green';
            disableCheckboxTitle = '"Unavailable because there are programmed dates"';
          }
          tpl += '<div class="tree-label"><a title="' + ref + '" class=\"' + nodeDisabledClasses + 'no-dnd never-dnd\" href=\"#\">' + title + complement + '</a></div>';
        } else {
          tpl += '<div class="tree-label">' + title + complement + '</div>';
        }
        if (contentEditable) {
          tpl += '<div class="right-part">';

          // Objects && arrays
          if (_.isObject(value)) {
            var isNodeJsonEditActivated = (config.get('featureFlipping') && config.get('featureFlipping').nodeJsonEdit === 'true');
            var isTaggedPath = TagsUtils.jsonPathEqualsTaggedRootPath(release.get('content_id'), ref, tagsMetadata.prefix);
            var isTaggedParentPath = TagsUtils.jsonPathEqualsTaggedRootPath(release.get('content_id'), Utils.getParentPath(ref, '.'), tagsMetadata.prefix);
            var isAtRootLevel = Utils.getParentPath(ref, '.') == '';

            if (isNodeJsonEditActivated) {
              var jsonEditClass = (isTaggedParentPath) ? 'json-edit-global json-edit-tagged' : 'json-edit-global';
              if (isAtRootLevel && !isCurrentUserDev) {
                jsonEditClass += ' hidden';
              }

              tpl += '<a href="#" data-guid="' + this.getGuid() + '.edit' + '" data-ref="' + ref + '.edit' + '" ' + 'data-title="' + title + '" class="no-dnd never-dnd btn json-edit-node glyphicon glyphicon-edit ' + jsonEditClass + '" title="Edit node in JSON format"></a>';
            }

            if (!_.isArray(value) && isTaggedParentPath && !isTaggedPath) {
              tpl += '<a href="#" data-guid="' + this.getGuid() + '.tag' + '" data-ref="' + ref + '.tag' + '" ' + 'data-title="' + title + '" class="no-dnd never-dnd btn json-edit-node-tag glyphicon glyphicon-tag" title="Edit tags"></a>';
            }

            if (!isAtRootLevel && (Utils.getLastPathPart(Utils.getParentPath(ref, '.'), '.') === PAGES_STR || Utils.getParentPath(ref, '.') == 'thematicPages')) {
              tpl += '<a href="#" data-guid="' + this.getGuid() + '.expandall' + '" data-ref="' + ref + '.expandall' + '" ' + 'data-title="' + title + '" class="no-dnd never-dnd btn json-expandall-node glyphicon glyphicon-fullscreen" title="Expand All"></a>';
              tpl += '<a href="#" data-guid="' + this.getGuid() + '.duplicatepage' + '" data-ref="' + ref + '.duplicatepage' + '" ' + 'data-title="' + title + '" class="no-dnd never-dnd btn" title="Duplicate Page"><img src="/assets/img/duplicate_page.png" class=" json-duplicatepage-node"/></a>';
            }
          }

          // Arrays
          if (_.isArray(value)) {
            // Add button
            if (schema && schema.items && schema.items.oneOf) {
              var oneOfTypeList = [];
              // Dropdown to select type to add
              tpl += '<select class=\'no-dnd never-dnd cms-select-module\' data-guid=\'' + this.getGuid() + '\' data-ref=\'' + ref + '\' ' + nodeDisabledAttributeForButtons + '>';
              _.each(schema.items.oneOf, (type) => {
                oneOfTypeList.push(type.$ref);
              });
              oneOfTypeList = oneOfTypeList.sort(Utils.naturalSort);
              _.each(oneOfTypeList, ($ref) => {
                tpl += '<option value="' + $ref + '">' + $ref.replace('#/definitions/', '') + '</option>';
              });
              tpl += '</select>';
              tpl += '<button type="button" class="no-dnd never-dnd btn json-add-oneof-type button button--shikoba button--primary button--xxs"' + nodeDisabledAttributeForButtons + '><i class="fa fa-plus"></i><span>Add</span></button>';
            } else if (schema && schema.items && schema.items.$ref) {
              var idForAddPageElement = "id='add-page-button'";

              tpl += '<button ' + idForAddPageElement + ' type=\'button\' class=\'no-dnd never-dnd btn json-add-type button button--shikoba button--primary button--xxs\' data-guid=\'' + this.getGuid() + '\' data-type=\'' + schema.items.$ref + '\' ' + nodeDisabledAttributeForButtons + '><i class=\'fa fa-plus\'></i><span>Add</span></button>';
            } else {
              tpl += '<button type=\'button\' class=\'no-dnd never-dnd btn json-add-node button button--shikoba button--primary button--xxs\' ' + nodeDisabledAttributeForButtons + '><i class=\'fa fa-plus\'></i><span>Add</span></button>';
            }

            // Import button
            tpl += '<button type="button" class="no-dnd never-dnd btn json-import-node button button--shikoba button--primary button--xxs"' + nodeDisabledAttributeForButtons + '><i class="fa fa-plus"></i><span>Import</span></button>';
          }

          // Array elements
          if (parentIsArray) {
            if (_.isObject(value)) {
              var isDisabledNodesActivated = (config.get('featureFlipping') && config.get('featureFlipping').disabledNodes === 'true');
              var isProgramDisabledNodesActivated = (config.get('featureFlipping') && config.get('featureFlipping').programDisabledNodes === 'true');
              if (_.isArray(value)) {
                if (schema && schema.items && schema.items.type && schema.items.type == 'object') {
                  if (isDisabledNodesActivated) {
                    disableCheckboxTitle = this.getNodeDisabled(this.model, schema, value, parentIsArray) ? '"Click to Enable"' : '"Click to Disable"';
                    statusClasses = this.getNodeDisabled(this.model, schema, value, parentIsArray) ? 'disabled fa-ban ' : 'enabled fa-check ';
                    if (isProgramDisabledNodesActivated && hasNodeProgramDisabledDates) {
                      disableCheckboxTitle = '"Unavailable because there are programmed dates"';
                      nodeDisabledAttributeForDisabledCheckbox = 'disabled=disabled';
                      statusClasses = 'locked fa-lock';
                    }
                    tpl += '<i  aria-hidden="false" data-guid="' + this.getGuid() + '.disable' + '" data-ref="' + ref + '.disable' + '" class="no-dnd never-dnd btn json-disable-node ' + statusClasses + ' " ' + ' title=' + disableCheckboxTitle + nodeDisabledAttributeForDisabledCheckbox + '></i> ';
                    if (isProgramDisabledNodesActivated) {
                      // Check if we're in a shared component section and if it's DCX content
                      const isSharedSection = ref && (ref.includes('sharedComponents') || ref.includes('sharedCommercialComponents'));
                      const contentId = release?.get('content_id');
                      const isDcx = isDcxContentId(contentId);

                      if (!isDcx || !isSharedSection) {
                        tpl += '<a href="#" data-guid="' + this.getGuid() + '.programDisable' + '" data-ref="' + ref + '.programDisable' + '" ' + 'data-title="' + title + '" class="no-dnd never-dnd btn json-program-disabled glyphicon glyphicon-time' + nodeProgramDisabledClass + '" title="Program enable/disable dates"></a>';
                      }
                    }
                  }
                }
              } else {
                if (isDisabledNodesActivated) {
                  disableCheckboxTitle = this.getNodeDisabled(this.model, schema, value, parentIsArray) ? '"Click to Enable"' : '"Click to Disable"';
                  statusClasses = this.getNodeDisabled(this.model, schema, value, parentIsArray) ? 'disabled fa-ban ' : 'enabled fa-check ';

                  if (isProgramDisabledNodesActivated && hasNodeProgramDisabledDates) {
                    disableCheckboxTitle = '"Unavailable because there are programmed dates"';
                    nodeDisabledAttributeForDisabledCheckbox = 'disabled=disabled';
                    statusClasses = 'locked fa-lock';
                  }

                  tpl += '<i  aria-hidden="false" data-guid="' + this.getGuid() + '.disable' + '" data-ref="' + ref + '.disable' + '" class=" fa  no-dnd never-dnd btn json-disable-node ' + statusClasses + ' " ' + ' title=' + disableCheckboxTitle + nodeDisabledAttributeForDisabledCheckbox + '></i> ';
                  if (isProgramDisabledNodesActivated && release.get('content_id') !== 'feature-flip') {
                    // Check if we're in a shared component section and if it's DCX content
                    const isSharedSection = ref && (ref.includes('sharedComponents') || ref.includes('sharedCommercialComponents'));
                    const contentId = release?.get('content_id');
                    const isDcx = isDcxContentId(contentId);

                    if (!isDcx || !isSharedSection) {
                      tpl += '<a href="#" data-guid="' + this.getGuid() + '.programDisable' + '" data-ref="' + ref + '.programDisable' + '" ' + 'data-title="' + title + '" class="no-dnd never-dnd btn json-program-disabled glyphicon glyphicon-time' + nodeProgramDisabledClass + '" title="Program enable/disable dates"></a>';
                    }
                  }
                }
              }
            }
            tpl += '<a href="#" data-guid="' + this.getGuid() + '.remove' + '" data-ref="' + ref + '.remove' + '" class="no-dnd never-dnd btn json-remove-node glyphicon glyphicon-remove" title="Delete"></a>';
          }
          tpl += '</div>';
        }
        // Non-object elements
        if (!_.isObject(value) && !_.isArray(value)) {
          const restrictedAccess = this.getRestrictedAccess(schema);
          const isReadOnly = READ_ONLY === restrictedAccess;
          var disabledAttr = contentEditable && !isReadOnly && (!restrictedAccess || isCurrentUserDev) ? '' : ' disabled';
          if (_.isBoolean(value)) {
            tpl += '<input type="checkbox" data-guid="' + this.getGuid() + '" data-ref="' + ref + '" class="value' + this.getDynamicClasses(value, contentValue, schema) + '" data-container="body" data-toggle="popover" data-placement="left" ' + (value ? 'checked' : '') + disabledAttr + ' >';
          } else {
            if (schema && schema.enum && schema.enum.length > 0) {
              if (value == '') {
                var defaultValueOfSelect = '--PLEASE SELECT ONE--';
                if (schema.enum[0] === defaultValueOfSelect) {
                  schema.enum.shift();
                }
                schema.enum.unshift(defaultValueOfSelect);
              }


              var data_width = '';
              var is_a_color = title && title.toString().toLowerCase().indexOf('color') !== -1;
              var is_icon = title && (title.toString().toLowerCase().indexOf('iconname') !== -1 || title.toString().toLowerCase() === 'icon');
              if (is_icon) {
                data_width = 'data-width="250px"';
              }
              if (is_a_color) {
                data_width = 'data-width="175px"';
              }

              tpl += '<select ' + data_width + ' class=\'no-dnd never-dnd cms-select-liveSearch selectpicker' + this.getEditableClass(schema) + '\' data-live-search=\'true\' data-guid=\'' + this.getGuid() + '\' data-ref=\'' + ref + '\' ' + disabledAttr + '>';

              for (var i = 0; i < schema.enum.length; i++) {
                var data_content = '';

                if (is_a_color) {
                  var color = Colors.getCssColorByName(schema.enum[i]);
                  color = color === undefined ? 'white' : color;
                  data_content = '<span style=\'background-color : ' + color + ' ; color : ' + color + ' \' class=\'badge\'> . </span>  ' + schema.enum[i];
                } else if (is_icon) {
                  var iconPath = Icons.getIconPath(schema.enum[i]);

                  data_content = iconPath ? '<img height=\'30px\' width=\'30px\' class=\'email\' src=\'' + iconPath + '\'>   ' + schema.enum[i] : schema.enum[i];
                }

                const disabledRegex = /--\s*.*?\s*--/;
                const isDisabled = disabledRegex.test(schema.enum[i]);
                const isSelected = value == schema.enum[i];
                const optionAttributes = `data-content="${data_content}" value="${schema.enum[i]}"`;
                const stateAttribute = isDisabled ? 'disabled' : isSelected ? 'selected' : '';

                tpl += `<option ${optionAttributes} ${stateAttribute}>${schema.enum[i]}</option>`;

              }
              tpl += '</select>';
            } else if (isDcxContentId(contentId) && isDescendantOfCommercialAnimation && Utils.getLastPathPart(ref, '.').toLowerCase().includes('referencename')) {
              const data_width = 'data-width="350px"';
              const key = !Utils.getLastPathPart(ref).includes('ReferenceName') ? Utils.getLastPathPart(Utils.getParentPath(ref, '.'), '.') : '';
              const relevantReferenceNamesList = getReleventSharedCommercialComponentsReferenceNames({ releaseValue: release?.get('value') ?? {}, key });
              
              tpl += `<select ${data_width} class="no-dnd never-dnd cms-select-liveSearch dcxSharedCommercialComponentsReferenceNamePicker selectpicker ${this.getDynamicClasses(value, contentValue, schema)}" data-live-search="true" data-guid="${this.getGuid()}" data-ref="${ref}" ${disabledAttr} data-key="${key}">`;
              tpl += '<option value="">-- NO REFERENCE --</option>';
              relevantReferenceNamesList.forEach((referenceName) => {
                const stateAttribute = value === referenceName ? 'selected' : '';
                tpl+= `<option value="${referenceName}" ${stateAttribute}>${referenceName}</option>`;
              });
              tpl += '</select>';
              
            } else if (isDcxContentId(contentId) && Utils.getLastPathPart(ref, '.') === 'referenceName' && !isDescendantOfSharedDataNode && isAChildOfPagesNode) {
              const data_width = 'data-width="350px"';
              const key = Utils.getLastPathPart(Utils.getParentPath(ref, '.'), '.');
              const relevantReferenceNamesList = getRelevantSharedDataReferenceNames({ releaseValue: release?.get('value') ?? {}, contentId, key, path: ref });
              
              tpl += `<select ${data_width} class="no-dnd never-dnd cms-select-liveSearch selectpicker dcxSharedComponentsReferenceNamePicker ${this.getDynamicClasses(value, contentValue, schema)}" data-live-search="true" data-guid="${this.getGuid()}" data-ref="${ref}" ${disabledAttr} data-key="${key}">`;
              tpl += '<option value="">-- NO REFERENCE --</option>';
              relevantReferenceNamesList.forEach((referenceName) => {
                const stateAttribute = value === referenceName ? 'selected' : '';
                tpl+= `<option value="${referenceName}" ${stateAttribute}>${referenceName}</option>`;
              });
              tpl += '</select>';

            } else {
              var autoformatRule = this.getAutoformatRule(contentEditable, schema);
              var autoformatClass = (autoformatRule) ? ' autoformat' : '';
              tpl += '<textarea cols="65" data-guid="' + this.getGuid() + '" data-ref="' + ref + '"' + autoformatRule + ' class="no-dnd never-dnd value' + this.getMarkdownClass(contentEditable, schema) + autoformatClass + this.getDynamicClasses(value, contentValue, schema) + '" data-container="body" data-toggle="popover" data-placement="left" ' + disabledAttr + '>';
              tpl += (value != null ? value : '') + '</textarea>';
            }
          }
        }
      }
      tpl += '</div>';
      return tpl;
    },

    getGuid: function getGuid() {
      var guid = this.model.get('guid');
      if (!guid) {
        guid = Utils.guid();
        this.model.set('guid', guid);
      }
      return guid;
    },

    getSchema: function getSchema(release) {
      var jsonPath = TreeItemTools.getModelJsonPath(this.model);
      return TreeItemTools.getSchema(this.model, jsonPath, release);
    },

    getRestrictedAccess: function getRestrictedAccess(schema) {
      return (schema && schema.metadata && schema.metadata.restrictedAccess);
    },

    getMarkdownClass: function getMarkdownClass(contentEditable, schema) {
      var markdownTextarea = (schema && schema.type && schema.type === 'string' && schema.metadata && schema.metadata.markdown);
      if (markdownTextarea) {
        if (contentEditable) {
          return ' markdown markdown-' + schema.metadata.markdown;
        }
        return ' markdown markdown-preview';
      }
      return '';
    },

    getAutoformatRule: function getAutoformatRule(contentEditable, schema) {
      var autoformatTextarea = (schema && schema.type && schema.type === 'string' && schema.metadata && schema.metadata.autoformat);
      if (autoformatTextarea && contentEditable) {
        return '" data-autoformat-rule="' + schema.metadata.autoformat + '"';
      }
      return '';
    },

    getEditableClass: function getEditableClass(schema) {
      const restrictedAccess = this.getRestrictedAccess(schema);
      const isReadOnly = READ_ONLY === restrictedAccess;
      return contentEditable && !isReadOnly && (!restrictedAccess || isCurrentUserDev) ? ' editable' : '';
    },

    getDynamicClasses: function getDynamicClasses(value, contentValue, schema) {
      var warningClass = value == contentValue ? ' warning' : '';
      var editableClass = this.getEditableClass(schema);
      var editionWarningClass = (contentEditable && this.getRestrictedAccess(schema)) ? ' edition-warning' : '';
      return warningClass + editableClass + editionWarningClass;
    },

    hasNodeProgramDisabledDates: function hasNodeProgramDisabledDates(model, modelValue, parentIsArray) {
      if (config.get('featureFlipping') && config.get('featureFlipping').disabledNodes === 'true' && config.get('featureFlipping').programDisabledNodes === 'true' && parentIsArray && _.isObject(modelValue)) {
        var programDisabled = model.get('programDisabled');
        if (!_.isBoolean(programDisabled)) {
          programDisabled = false;
          if (!_.isArray(modelValue)) {
            programDisabled = (modelValue && modelValue['@metadata'] && (modelValue['@metadata'].enableStartDate || modelValue['@metadata'].disableStartDate)) ? true : false;
          }
          model.set('programDisabled', programDisabled);
          var enableStartDate = (programDisabled) ? _.get(modelValue, '@metadata.enableStartDate') : '';
          var disableStartDate = (programDisabled) ? _.get(modelValue, '@metadata.disableStartDate') : '';
          model.set('enableStartDate', enableStartDate);
          model.set('disableStartDate', disableStartDate);
        }
        return programDisabled;
      }
      return false;
    },

    getNodeProgramDisabled: function getNodeProgramDisabled(model, modelValue) {
      var nodeDisabled = isProgramDisabled({
        enableStartDate: modelValue['@metadata'].enableStartDate,
        disableStartDate: modelValue['@metadata'].disableStartDate,
      });
      model.set('disabled', nodeDisabled);
      return nodeDisabled;
    },

    getNodeDisabled: function getNodeDisabled(model, schema, modelValue, parentIsArray) {
      if (config.get('featureFlipping') && config.get('featureFlipping').disabledNodes === 'true' && parentIsArray && _.isObject(modelValue)) {
        var nodeDisabled = model.get('disabled');
        if (!_.isBoolean(nodeDisabled)) {
          nodeDisabled = false;
          if (_.isArray(modelValue)) {
            if (modelValue.length) {
              if (schema && schema.items && schema.items.type && schema.items.type == 'object') {
                var disabledElements = modelValue.filter((elementValue) => {
                  return elementValue && elementValue['@metadata'] && elementValue['@metadata'].disabled;
                });
                if (disabledElements.length === modelValue.length) {
                  nodeDisabled = true;
                }
              }
            }
          } else {
            nodeDisabled = (modelValue && modelValue['@metadata'] && modelValue['@metadata'].disabled) ? true : false;
          }
          model.set('disabled', nodeDisabled);
        }
        return nodeDisabled;
      }
      return false;
    },

  });
};

var TreeItemFactory = {
  TreeItemConstructor: TreeItemConstructor,
};
module.exports = TreeItemFactory;
